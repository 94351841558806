import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { Container } from 'react-bootstrap';

const BlogPost = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const token =
          '9d24d8c9412f3d14b0f708ced03fce2e1e8662b200c910e742b592f35f58550c059617b54d53d284690141f5cfc725b97a7c17e5841dd8c8ca099c0b0911d28a74464453ba1ffa2bd5b6b79ab0f35831dba6df77b70d031b219fb8342d112459e5d56b6c9896f19ff086341a1a3e6424b16aacc13c02926c28a627b44bf9c4d2'; // Replace with your bearer token
        const response = await axios.get(`http://localhost:1337/api/blogs/${id}?populate=*`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setPost(response.data.data);
        console.log(response.data.data.attributes.coverImage.data.attributes.url);
      } catch (error) {
        console.error(error);
      }
    };

    fetchPost();
  }, [id]);

  if (!post) {
    return <div>Loading...</div>;
  } else
    return (
      <div>
        <Container className="blog-post-markdown mt-5">
          {console.log(post)}
          <h2>{post?.attributes.title}</h2>
          <ReactMarkdown>{post?.attributes.content}</ReactMarkdown>
        </Container>
      </div>
    );
};

export default BlogPost;
