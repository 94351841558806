import React from 'react';

const Cookies = () => {
  return (
    <div className="blog-posts-container">
      <h1 className="blog-posts-heading"> Bruk av informasjonskapsler (cookies) på vår nettside</h1>
      <div className="blog-posts-list">
        <h2>Hva er informasjonskapsler?</h2>
        <p>
          Informasjonskapsler, ofte kalt cookies, er små tekstfiler som lagres på din enhet (datamaskin, mobiltelefon,
          nettbrett eller annen enhet) når du besøker visse nettsider. De brukes for å hjelpe nettsider til å fungere,
          eller fungere mer effektivt, samt å gi informasjon til eierne av nettsiden.
        </p>{' '}
        <h2>Hvordan bruker vi informasjonskapsler?</h2>
        <p>
          Vår nettside bruker informasjonskapsler for å forbedre brukeropplevelsen, samt for å samle inn anonymisert
          statistikk om bruk av nettsiden for å forstå hvordan besøkende bruker den. Dette hjelper oss med å forbedre
          innholdet og funksjonaliteten på vår nettside.
        </p>{' '}
        <h2>Google Analytics</h2>
        <p>
          Vi bruker Google Analytics for å analysere bruken av vår nettside. Google Analytics samler inn informasjon om
          nettsidebesøk for å gi oss en oversikt over hvordan besøkende bruker nettsiden. Dataene som samles inn er
          anonymisert og inneholder ingen personlig identifiserbar informasjon. Google's personvernpolicy kan leses her:
          Google Analytics personvernpolicy.
        </p>{' '}
        <h2>Hvordan administrere informasjonskapsler?</h2>
        <p>
          De fleste nettlesere tillater deg å nekte å akseptere informasjonskapsler og å slette informasjonskapsler.
          Metodene for å gjøre dette varierer fra nettleser til nettleser, og fra versjon til versjon. Du kan imidlertid
          finne oppdatert informasjon om blokkering og sletting av informasjonskapsler ved å sjekke hjelpeseksjonen i
          nettleseren din eller ved å besøke www.aboutcookies.org.
        </p>
        <h2>Samtykke til bruk av informasjonskapsler</h2>
        <p>
          Ved å fortsette å bruke vår nettside uten å endre innstillingene i nettleseren din, antar vi at du samtykker i
          vår bruk av informasjonskapsler som beskrevet i denne klausulen.
        </p>
        <p>Hvis du har spørsmål om vår bruk av informasjonskapsler, vennligst kontakt oss.</p>
      </div>
    </div>
  );
};

export default Cookies;
