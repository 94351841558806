import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";

const BlogPosts = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const token =
          "9d24d8c9412f3d14b0f708ced03fce2e1e8662b200c910e742b592f35f58550c059617b54d53d284690141f5cfc725b97a7c17e5841dd8c8ca099c0b0911d28a74464453ba1ffa2bd5b6b79ab0f35831dba6df77b70d031b219fb8342d112459e5d56b6c9896f19ff086341a1a3e6424b16aacc13c02926c28a627b44bf9c4d2"; // Replace with your bearer token
        const response = await axios.get(
          "http://localhost:1337/api/blogs?populate=*",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        setPosts(response.data.data);
        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchPosts();
  }, []);

  return (
    <div className="blog-posts-container">
      <h1 className="blog-posts-heading">Blog Posts</h1>
      <div className="blog-posts-list">
        {posts.map((post) => (
          <NavLink
            key={post.id}
            to={`/blog/${post.id}`}
            className="blog-post-link"
            activeStyle={{ fontWeight: "bold" }}
          >
            <div className="blog-post-title">{post.attributes.title}</div>
            <img
              loading="lazy"
              src={`http://localhost:1337${post.attributes.coverImage.data.attributes.url}`}
              height="156px"
              alt="Cover"
              className="blog-post-cover-image"
            />
            <div className="blog-post-description">
              {post.attributes.description}
            </div>
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default BlogPosts;
