import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const BookMeetingButton = ({ lg }) => {
  const { t } = useTranslation();

  // Determine the button class based on the lg prop
  const buttonClass = lg
    ? "btn btn-primary btn-lg fw-bold"
    : "btn btn-primary fw-bold";

  return (
    <div className="text-center">
      <a
        href="https://outlook.office.com/bookwithme/user/fc4645d2d84d4ae2950173f3df0700e4@norskdatateknikk.no/meetingtype/DfYFsfTBwEWZx4rvDtIWbg2?anonymous&ep=mlink"
        target="_blank"
        rel="noopener noreferrer"
      >
        <button type="button" className={buttonClass}>
          {t("bookMeetinglink")}
        </button>
      </a>
    </div>
  );
};

BookMeetingButton.propTypes = {
  lg: PropTypes.bool,
};

export default BookMeetingButton;
