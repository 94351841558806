import React from "react";
import { useTranslation } from "react-i18next";
import SectionsWithImage from "./sectionsWithImage";

const Services = () => {
  const { t } = useTranslation();

  const sections = [
    {
      title: t("serviceSections.electronics.title"),
      description: t("serviceSections.electronics.description"),
      image: `${process.env.PUBLIC_URL}/assets/capabilities/electronicsCapabilities.webp`,
    },
    {
      title: t("serviceSections.prototyping.title"),
      description: t("serviceSections.prototyping.description"),
      image: `${process.env.PUBLIC_URL}/assets/capabilities/mechanicalCapabilities.webp`,
    },
    {
      title: t("serviceSections.software.title"),
      description: t("serviceSections.software.description"),
      image: `${process.env.PUBLIC_URL}/assets/capabilities/softwareCapabilities.webp`,
    },
  ];

  return (
    <div>
      <h1 className="text-center">Våre tjenester</h1>
      <SectionsWithImage sections={sections} />
    </div>
  );
};

export default Services;
