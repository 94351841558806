import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Element } from "react-scroll";
import { useTranslation } from "react-i18next";
import Projects from "./Projects/Projects";

import About from "./About";
import Customers from "./Customers";
import Services from "./Services";
import HeroSection from "./HeroSection";
import BookMeetingButton from "./BookMeeting";

const Home = () => {
  const { t } = useTranslation();

  return (
    <>
      <HeroSection />

      <Row className="my-5">
        <Element name="Services">
          <Services />
        </Element>
      </Row>
      <Element name="Projects">
        <Projects />
      </Element>
      <Row className="my-5">
        <Col md={12}>
          <Customers />
        </Col>
      </Row>
      <Row className="mt-4">
        <Element name="customers">
          <Container>
            <About />
          </Container>
        </Element>
      </Row>
      <Row>
        <Container>
          <BookMeetingButton lg />
        </Container>
      </Row>
    </>
  );
};

export default Home;
