import React from "react";
import { useTranslation } from "react-i18next";

const Customers = () => {
  const { t } = useTranslation();

  const logos = [
    "lango-service.webp",
    "hansen-protection.webp",
    "kda.webp",
    "vegvesen-logo-farger-pos-rgb.webp",
    "anteo.webp",
    "optoscale.webp",
    "lildog.webp",
    "daal.webp",
  ];

  const duplicatedLogos = [...logos, ...logos]; // Duplicate logos to make it look infinite

  return (
    <div className="container py-4 text-center px-5">
      <h1 className="pt-3 pb-3 mb-3">{t("trustedBy")}</h1>
      <div className="logo-reel-wrapper">
        <div className="logo-reel">
          {duplicatedLogos.map((logo, index) => (
            <div className="logo-container" key={index}>
              <img
                loading="lazy"
                src={`${process.env.PUBLIC_URL}/assets/customersLogos/${logo}`}
                alt={logo.replace(".webp", "").replace("-", " ")}
                className="img-fluid customer-logo"
              />
            </div>
          ))}
          {duplicatedLogos.map((logo, index) => (
            <div
              className="logo-container"
              key={index + duplicatedLogos.length}
            >
              <img
                loading="lazy"
                src={`${process.env.PUBLIC_URL}/assets/customersLogos/${logo}`}
                alt={logo.replace(".webp", "").replace("-", " ")}
                className="img-fluid customer-logo"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Customers;
