// src/pages/DownloadPage.js
import React from "react";
import DownloadPDFButton from "./downloadPDFButton";
import { useTranslation } from "react-i18next";
import BookMeetingButton from "../BookMeeting";
import { Col, Row } from "react-bootstrap";

const DownloadPage = () => {
  const { t } = useTranslation();

  return (
    <div className="DownloadPage">
      <div className="text-center mt-3  animated-fadeInLeft">
        <div className="container mt-5">
          <h1 className="mb-4 display-3 font-weight-bold">{t("welcome")}</h1>
          <div className="text-background">
            <p className="lead">{t("yourFreePDF")}</p>
            <Row className="my-5">
              <Col>
                <DownloadPDFButton />
              </Col>
              <Col>
                <BookMeetingButton />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadPage;
