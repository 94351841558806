import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from './locales/en/translation.json';
import noTranslation from './locales/no/translation.json';

const resources = {
  en: {
    translation: enTranslation,
  },
  no: {
    translation: noTranslation,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem('language') || 'no', // if no language in local storage, default to 'no'
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
