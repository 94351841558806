// src/components/downloadPage/downloadPDFButton.js
import React from "react";
import { useTranslation } from "react-i18next";

const DownloadPDFButton = () => {
  const { t } = useTranslation();
  const getFileName = () => {
    const path = window.location.hash;
    console.log(path);

    switch (path) {
      case "#/requirements-template":
        return "download/TMP100100-82A-Kravspesifikasjonsmal.pdf";
      case "/path2":
        return "another-file.pdf";
      default:
        return null; // or a default file if necessary
    }
  };

  const fileName = getFileName();
  const filePath = fileName ? `/assets/${fileName}` : null;

  return (
    <div className="text-center">
      {filePath ? (
        <a href={filePath} download>
          <button className="btn btn-primary-outline">
            {t("downloadPDF")}
          </button>
        </a>
      ) : (
        <p>No file available for download</p>
      )}
    </div>
  );
};

export default DownloadPDFButton;
