import React from "react";
import { Carousel, Row, Col, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ProjectSection from "./ProjectsSection";

const Projects = () => {
  const { t } = useTranslation();

  const projects = [
    {
      image: `${process.env.PUBLIC_URL}/assets/products/sensor_sandmoen.webp`,
      title: t("featuredProjects.dekkskanner.title"),
      description: [
        t("featuredProjects.dekkskanner.description1"),
        t("featuredProjects.dekkskanner.description2"),
        t("featuredProjects.dekkskanner.description3"),
        t("featuredProjects.dekkskanner.description4"),
      ],
      bgColor: "#232931",
    },
    {
      image: `${process.env.PUBLIC_URL}/assets/products/dokuly.png`,
      title: t("featuredProjects.dokuly.title"),
      description: [
        t("featuredProjects.dokuly.description1"),
        t("featuredProjects.dokuly.description2"),
        t("featuredProjects.dokuly.description3"),
      ],
      bgColor: "#23655d",
      link: "https://dokuly.com/",
    },
    {
      image: `${process.env.PUBLIC_URL}/assets/products/nct.webp`,
      title: t("featuredProjects.nct.title"),
      description: [
        t("featuredProjects.nct.description1"),
        t("featuredProjects.nct.description2"),
        t("featuredProjects.nct.description3"),
        t("featuredProjects.nct.description4"),
      ],
      bgColor: "#285f65",
    },
    {
      image: `${process.env.PUBLIC_URL}/assets/products/lildog.webp`,
      title: t("featuredProjects.lilbit.title"),
      description: [
        t("featuredProjects.lilbit.description1"),
        t("featuredProjects.lilbit.description2"),
        t("featuredProjects.lilbit.description3"),
        t("featuredProjects.lilbit.description4"),
      ],
      bgColor: "#22411c",
      link: "https://lildog.com/",
    },
    {
      image: `${process.env.PUBLIC_URL}/assets/products/bremsetester_promo.webp`,
      title: t("featuredProjects.lango-service.title"),
      description: [
        t("featuredProjects.lango-service.description1"),
        t("featuredProjects.lango-service.description2"),
        t("featuredProjects.lango-service.description3"),
      ],
      bgColor: "#184062",
      link: "https://www.lango-service.no/produktmappe/truck/bremsetester/",
    },
  ];
  return (
    <Container>
      <Row className="my-5">
        <Col md={12}>
          <h2 className="text-center mt-4 mb-2 pb-3 cool-text">
            {t("chosenProjects")}
          </h2>
          <Carousel
            style={{ maxHeight: "600px", border: "none" }}
            indicators={true}
          >
            {projects.map((project, idx) => (
              <Carousel.Item
                key={idx}
                style={{ maxHeight: "600px", borderRadius: "10px" }}
              >
                <ProjectSection
                  image={project.image}
                  title={project.title}
                  description={project.description}
                  bgColor={project.bgColor}
                  link={project.link}
                  imagePosition="left"
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </Col>
      </Row>
    </Container>
  );
};

export default Projects;
