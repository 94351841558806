import React from "react";

const ProjectSection = ({ image, title, description, link, bgColor }) => {
  const getBrightness = (color) => {
    let r, g, b;
    if (color.match(/^rgb/)) {
      const match = color.match(/rgba?\((\d+),\s*(\d+),\s*(\d+)/);
      r = match[1];
      g = match[2];
      b = match[3];
    } else {
      let hex = color.substring(1);
      if (hex.length === 3) {
        hex = hex
          .split("")
          .map((hex) => hex + hex)
          .join("");
      }
      const rgb = parseInt(hex, 16);
      r = (rgb >> 16) & 0xff;
      g = (rgb >> 8) & 0xff;
      b = (rgb >> 0) & 0xff;
    }

    return (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  };

  const textColor = getBrightness(bgColor) > 0.5 ? "#000" : "#fff";
  const linkColor = getBrightness(bgColor) > 0.5 ? "#fff" : "#000";

  const descriptionArray = Array.isArray(description)
    ? description
    : [description];

  return (
    <div className="project-section" style={{ backgroundColor: bgColor }}>
      <div
        className="project-image"
        style={{ backgroundImage: `url(${image})` }}
      />
      <div className="project-content">
        <div className="project-info" style={{ color: textColor }}>
          <h2>{title}</h2>
          <ul>
            {descriptionArray.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
          {link && (
            <a
              href={link}
              className="project-button px-5"
              style={{ color: linkColor, backgroundColor: textColor }}
            >
              <span style={{ marginLeft: "5px", fontSize: "20px" }}>➡</span>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectSection;
