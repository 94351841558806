import React from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";

import Home from "./components/Home";
import Navbar from "./components/Navbar";
import About from "./components/About";
import "./i18n";
import Footer from "./components/Footer";
import BlogPosts from "./components/blog/BlogPosts";
import BlogPost from "./components/blog/BlogPost";
import Cookies from "./components/Cookies";
import { Row } from "react-bootstrap";
import DownloadPage from "./components/downloadPage/downloadPage";

function App() {
  return (
    <>
      <Router>
        <>
          <Navbar />
          <>
            <Row className="minimum-height mx-0">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/blog" element={<BlogPosts />} />
                <Route path="/blog/:id" element={<BlogPost />} />
                <Route path="/cookies" element={<Cookies />} />
                {/* <Route path="/store" element={<ShopifyStore />} /> */}
                <Route
                  path="/requirements-template"
                  element={<DownloadPage />}
                />
              </Routes>
            </Row>
          </>
          <Footer />
        </>
      </Router>
    </>
  );
}

export default App;
