import React from "react";
import { Element } from "react-scroll";
import { Container, Row, Col } from "react-bootstrap";
import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();

  const [copiedEmail, setCopiedEmail] = useState({});
  const [copiedPhone, setCopiedPhone] = useState({});

  const handleCopy = (id, email = false, phone = false) => {
    if (email) setCopiedEmail({ ...copiedEmail, [id]: true });
    if (phone) setCopiedPhone({ ...copiedPhone, [id]: true });
    setTimeout(() => {
      if (email) setCopiedEmail({ ...copiedEmail, [id]: false });
      if (phone) setCopiedPhone({ ...copiedPhone, [id]: false });
    }, 2000);
  };

  const employees = [
    {
      id: 1,
      image: `${process.env.PUBLIC_URL}/assets/employees/hole.webp`,
    },
    {
      id: 2,
      image: `${process.env.PUBLIC_URL}/assets/employees/buer.webp`,
    },
    {
      id: 3,
      image: `${process.env.PUBLIC_URL}/assets/employees/gaute.webp`,
    },
    {
      id: 4,
      image: `${process.env.PUBLIC_URL}/assets/employees/lars.webp`,
    },
    // ... add more employees
  ];

  return (
    <Element name="about">
      <Container>
        <Row className="my-5 py-5">
          <Col md={12}>
            <h2 className="text-center mb-3">{t("contact_us")}</h2>
            <Row>
              {employees.map((employee) => (
                <Col key={employee.id} xs={12} sm={10} md={6} className="mb-4">
                  <Row>
                    <Col xs={10} sm={6} md={6} className="image-container">
                      <img
                        loading="lazy"
                        src={employee.image}
                        alt={t(`employees.${employee.id}.name`)}
                        className="employee-image"
                      />
                    </Col>
                    <Col xs={12} sm={6} md={6}>
                      <div className="employee-details text-left">
                        <span className="employee-name">
                          {t(`employees.${employee.id}.name`)}
                        </span>
                        <span className="employee-position">
                          {t(`employees.${employee.id}.position`)}
                        </span>
                        {t(`employees.${employee.id}.email`) !== "" && (
                          <CopyToClipboard
                            text={t(`employees.${employee.id}.email`)}
                            onCopy={() => handleCopy(employee.id, true)}
                          >
                            <span
                              style={{
                                cursor: "pointer",
                                fontSize: "1.2em",
                                color: "#285f65",
                                display: "block",
                                marginTop: "10px",
                                borderBottom: "1px solid #285f65",
                                padding: "5px",
                              }}
                            >
                              <strong>
                                {copiedEmail[employee.id]
                                  ? "Copied!"
                                  : t(`employees.${employee.id}.email`)}
                              </strong>
                            </span>
                          </CopyToClipboard>
                        )}

                        <br />
                        <CopyToClipboard
                          text={t(`employees.${employee.id}.phone`)}
                          onCopy={() => handleCopy(employee.id, false, true)}
                        >
                          <span style={{ cursor: "pointer" }}>
                            <strong>
                              {copiedPhone[employee.id]
                                ? "Copied!"
                                : t(`employees.${employee.id}.phone`)}
                            </strong>
                          </span>
                        </CopyToClipboard>
                      </div>
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </Element>
  );
};

export default About;
