import React, { useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

const SectionsWithImage = ({ sections = [] }) => {
  const [activeSection, setActiveSection] = useState(0);

  const createMarkup = (text) => {
    return { __html: text.replace(/\n/g, "<br/>") };
  };

  return (
    <Container className="mt-5">
      {/* Desktop Layout */}
      <Row className="d-none d-lg-flex align-items-center">
        <Col lg={6} className="mb-4">
          {sections.map((section, index) => (
            <Card
              key={index}
              className={`mb-4 section-card ${activeSection === index ? "active" : ""}`}
              onClick={() => setActiveSection(index)}
              style={{ cursor: "pointer" }}
            >
              <Card.Body>
                <Card.Title className="h3 bold">{section.title}</Card.Title>
                <Card.Text className="bold">
                  <span
                    dangerouslySetInnerHTML={createMarkup(section.description)}
                  />
                </Card.Text>
              </Card.Body>
            </Card>
          ))}
        </Col>
        <Col lg={6}>
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "100%" }}
          >
            <img
              loading="lazy"
              src={sections[activeSection]?.image}
              alt={sections[activeSection]?.title}
              className="img-fluid"
              style={{
                width: "100%",
                height: "auto",
                maxHeight: "80vh",
                borderRadius: "1rem",
              }}
            />
          </div>
        </Col>
      </Row>
      {/* Mobile Layout */}
      <Row className="d-lg-none">
        {sections.map((section, index) => (
          <Col xs={12} key={index} className="mb-4">
            <Card className="section-card mb-2">
              <Card.Body>
                <Card.Title className="h3 bold">{section.title}</Card.Title>
                <Card.Text className="bold">
                  <span
                    dangerouslySetInnerHTML={createMarkup(section.description)}
                  />
                </Card.Text>
              </Card.Body>
            </Card>
            <div className="text-center mt-2">
              <img
                loading="lazy"
                src={section.image}
                alt={section.title}
                className="img-fluid"
                style={{
                  width: "100%",
                  height: "auto",
                  maxHeight: "80vh",
                  borderRadius: "1rem",
                }}
              />
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default SectionsWithImage;
