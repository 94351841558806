import React from "react";
import { useTranslation } from "react-i18next";
import { Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { Link } from "react-scroll";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer mt-auto py-3 footer-background">
      <div className="container text-center footer-text mt-5">
        <Row>
          <span className="">
            &copy; {new Date().getFullYear()} {t("norskDatateknikk")} |
            Bratsbergvegen 2E / Sorgenfrivegen 16, 7031 Trondheim |
            kontakt@norskdatateknikk.no |{" "}
            <NavLink to="/cookies">Informasjonskapsler</NavLink>
          </span>
        </Row>
        <Row>
          <button className="btn footer-btn  mt-3">
            <Link to="home" offset={-120}>
              {t("toTop")}
            </Link>
          </button>
        </Row>
      </div>
    </footer>
  );
};

export default Footer;
